import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import './UserForm.scss';

import FormField from '../Form/Field';
import Button from "../Button/Button";
import MappingList from './MappingList';
import WithDataRequiredContainer from "../WithDataRequiredContainer/WithDataRequiredContainer";
import ConfirmationModal from '../ConfirmationModal';

import useUsersActions from "../../store/actions/usersActions";
import usePropertyActions from "../../store/actions/propertiesActions";
import usePropertyGroupActions from "store/actions/propertyGroupActions";

import { withAuthorization } from "../../session";
import {
    getIdToEdit,
    getFormFieldConfigs,
    getFormFieldDefaultValues,
    validate,
    mergeProperties,
    regroupProperties,
    validateAllFields,
    getSelectedItems,
    getUserTypes
} from './helper';
import { useNavigate } from "react-router-dom";

const UsersForm = ({ match }) => {
    const navigate = useNavigate();
    const state = useSelector((state) => ({
        users: state.users,
        properties: state.properties,
        propertyGroups: state.propertyGroups
    }));

    const {
        addUser,
        getUser,
        updateUser,
        getUsers,
        clearUser,
    } = useUsersActions();

    const { getProperties } = usePropertyActions();
    const { loadPropertyGroupsState } = usePropertyGroupActions();

    // const combinedFields = [...user_information, user_permissions];
    const [isPageLoading, setIsPageLoading] = useState(true);
    const [searchItems, setSearchItems] = useState([]);
    const [isEdit, setIsEdit] = useState(getIdToEdit(match)? true: false);
    const [showSubmitModal, setShowSubmitModal] = useState(false);
    const [showUnsavedChangesModal, setShowUnsavedChangesModal] = useState(false);
    const [pageError, setPageError] = useState(null);
    const [originalValues, setOriginalValues] = useState({});
    const [values, setValues] = useState({});
    const [errors, setErrors] = useState({});

    useEffect(() => {
        const userId = getIdToEdit(match);
        
        document.title = userId
            ? "Rev & You | Edit User"
            : "Rev & You | Add User";

        const initializeSearchItems = (propertyListResult, propertyGroupListResult, userResult) => {
            const newSearchItems = [];
            const propertyList = propertyListResult?.response?.data?.data;
            const propertyGroupList = propertyGroupListResult?.response?.data?.data;
            const user = userResult?.response.data.data;

            if (Array.isArray(propertyList)) {
                propertyList.forEach(property => newSearchItems.push({
                    id: property.id,
                    code: property.property_code,
                    name: property.name,
                    type: "PROPERTY"
                }));
            };

            if (propertyGroupList) {
                propertyGroupList.forEach(propertyGroup => newSearchItems.push({
                    id: propertyGroup.id,
                    code: propertyGroup.code,
                    name: propertyGroup.name,
                    type: "PROPERTY_GROUP"
                }))
            }

            if (user?.name) {
                document.title = `Rev & You | Edit ${user.name}`;
                const newUserData = { ...user };
                newUserData.merged_properties = mergeProperties(user.properties, user.property_groups);
                setOriginalValues(newUserData);
                setValues(newUserData);
            }

            return newSearchItems;
        }

        if (isEdit) {
            Promise.all([
                getProperties("", "name", 1), 
                loadPropertyGroupsState("", "name", 1),
                getUser(userId)
            ]).then(([propertyListResult, propertyGroupListResult, userResult]) => {
                setSearchItems(initializeSearchItems(propertyListResult, propertyGroupListResult, userResult));
                setIsPageLoading(false);
            });
        } else {
            Promise.all([
                getProperties("", "name", 1), 
                loadPropertyGroupsState("", "name", 1),
            ]).then(([propertyListResult, propertyGroupListResult]) => {
                setSearchItems(initializeSearchItems(propertyListResult, propertyGroupListResult));
                setIsPageLoading(false);
            });
            
            setValues(getFormFieldDefaultValues(getFormFieldConfigs()));
        }

        return () => {
            clearUser();
        };

    }, []);

    const formFieldEventHandler = ({ action, name, value }) => {
        const newValues = { ...values };

        if (name == "merged_properties") {
            const { properties, propertyGroups } = regroupProperties(
                value, 
                state.properties.properties,
                state.propertyGroups.list.data
            );
            newValues.properties = properties;
            newValues.property_groups = propertyGroups;
        }

        setValues({ ...newValues, [name]: value });

        const newErrors = {};
        validate(getFormFieldConfigs(name), value).forEach(error => {
            newErrors[error.field] = error.message;
        });

        if (newErrors[name] == undefined) newErrors[name] = undefined;

        setErrors({...errors, ...newErrors});
    }

    const handleSubmit = () => {
        const newErrors = {};
        validateAllFields(getFormFieldConfigs(), values).forEach(error => {
            newErrors[error.field] = error.message;
        });

        setErrors(newErrors);

        if (Object.keys(newErrors).length > 0) {
            // notifyUser("ERROR", "Unable to proceed. Please check the value of the fields.");
            return;
        }

        setShowSubmitModal(true);
    }
    const handleCancel = () => {
        if (isEdit) {
            const changedKeys = Object.keys(originalValues).filter(key => originalValues[key] != values[key]);
            if (changedKeys.length > 0) setShowUnsavedChangesModal(true);
            else navigate(-1);
        } else {
            navigate(-1);
        }
    };

    return (
        <div className="forms page manage-user">
            <div className="divider"></div>
            <div className="container centered">
                <div className="row">
                    <div className="forms-header">
                        <h2>MANAGE USERS</h2>
                    </div>
                </div>
            </div>
            <WithDataRequiredContainer
                doneFetching={!isPageLoading}
                height={"300px"}
            >
                <div className="form-layout">
                    <div className="centered-content">
                        <div className="form-layout-row">
                            <div className="form-layout-column"> 
                                <div className="form-container">
                                    <FormField label="NAME OF USER" type="textbox" name="name"
                                        value={values.name} error={errors.name} eventHandler={formFieldEventHandler}/>

                                    <FormField label="EMAIL" type="textbox" name="email" 
                                        value={values.email} error={errors.email} eventHandler={formFieldEventHandler}/>
                                </div>
                            </div>
                            <div className="form-layout-column">
                                {state.users.permissions.canChangePermissions && (
                                <FormField label="Permissions" type="radiogroup" name="type" className="user-permissions" 
                                    value={values.type} error={errors.type} eventHandler={formFieldEventHandler}
                                    items={getUserTypes()}/>
                                )}
                            </div>
                        </div>
                        <div className="form-layout-row">
                            <MappingList
                                allItems={searchItems} 
                                className={errors.merged_properties? "property-list-error": ""}
                                selectedItems={values.merged_properties?? []}
                                eventHandler={(event) => {
                                    if (!['add', 'delete'].includes(event.action)) return;
                                    const newSelectedItems = getSelectedItems(
                                        event, 
                                        [...searchItems], 
                                        [...values.merged_properties?? []]
                                    );

                                    formFieldEventHandler({ 
                                        action: "edit_properties", 
                                        name: "merged_properties", 
                                        value: newSelectedItems
                                    });
                                }}
                            />
                        </div>
                        <div className="form-layout-row form-options">
                            <Button className="submit" type="primary" disabled={false} 
                                onClick={handleSubmit}>
                                Submit
                            </Button>
                            <Button type="bg-dark" 
                                onClick={handleCancel}>
                                Cancel
                            </Button>
                        </div>
                    </div>
                </div>
            </WithDataRequiredContainer>
            <ConfirmationModal 
                visible={showSubmitModal} 
                width={isEdit? 400: 200 }
                text={<center><p>{isEdit? "Are you sure you want to submit changes?": "Create new user?"}</p></center>} 
                eventHandler={({ action }) => {
                    switch(action) {
                        case "submit":
                            const newUserData = { ...values };
                            delete newUserData.merged_properties;
                            newUserData.properties = values.properties.map(property => {
                                return { _id: property._id };
                            });
                            if (isEdit) {
                                const userId = getIdToEdit(match);
                                updateUser(userId, newUserData);
                            } else {
                                addUser(newUserData).then((result) => {
                                    getUsers().then((r) => navigate(-1));
                                });
                            }
                            break;
                        case "cancel": 
                            break;
                    }
                    setShowSubmitModal(false);
                }}
            />
            <ConfirmationModal visible={showUnsavedChangesModal} 
                text={<center><p>You have unsaved changes. Are you sure you want to leave this page?</p></center>} 
                confirmText="Yes"
                cancelText="Cancel"
                width={500}
                eventHandler={({ action }) => {
                    switch(action) {
                        case "submit": navigate(-1); break;
                        case "cancel": break;
                    }
                    setShowUnsavedChangesModal(false); 
                }}
            />
        </div>
    );
};

export default withAuthorization("canManageUsers")(UsersForm);
